<template>
    <div>
        <!--TODO: Show in new tab and call pdf print function-->
        <div class="download-content">
            <div>
                <h1 v-if="download == 'ready'">{{programTitle}}</h1>
                <div v-if="download == 'ready'" class="acyearRow">{{$t('programList.academicYear')}} : {{$route.params.acYear}}</div>
                <course v-if="download == 'ready'" h2 :course="blob.baseitem" />
                <spinner v-if="download == 'busy'" />
            </div>
        </div>
    </div>
</template>
<script>
/*eslint-disable */
import Spinner from "common/components/General/Spinner.vue";
import Course from "common/components/Pdf/Course.vue";
import { mapState } from "vuex";


export default {
	components: { Course, Spinner },
	data() {
		return {
            blob: {},
            download: "idle",
            programTitle: ""
		};
        },
    computed: {
		...mapState(["program"])
    },
    created() {
        this.ExportCourse()
        },

	methods: {
        async ExportCourse() {
            var model = this;
            model.download = "busy";
            var id = this.$route.params.programSlug;
			var acYear = this.$route.params.acYear
            var key = acYear + id;
            var baseitemid = this.$route.params.baseItemId
            var params = { programSlug: id, baseItemId: baseitemid, acYear: acYear, forceCache: true };
			await model.$store.dispatch("getCourse", params);
			await model.$store.dispatch("getProgram", params);
            model.programTitle = model.$store.state.program[key].details.Title;
            model.blob.baseitem = model.$store.state.program[key].courses[baseitemid];
			model.download = "ready";	
            setTimeout(function () {
				model.CallPrintFunction()
            }, 2000)
		},
		SortBaseItems(baseItems) {
			var model = this;
			if (baseItems === null) return;
			//sort base items by grouping
			var groupings = new Object();
			baseItems.forEach(function(item) {
				for (var i in item.Groupings) {
					var group = item.Groupings[i];
					if (!groupings[group]) {
						groupings[group] = { title: group, baseItems: [] };
					}
					groupings[group].baseItems.push(item);
				}
			});
			// sort, and split into rows of two
			var groupingArray = [];
			for (var grouping in groupings) {
				groupings[grouping].baseItems.sort(function(a, b) {
					return model.CompareBaseItems(a, b);
				});
				groupingArray.push(groupings[grouping]);
			}
			groupingArray.sort(function(a, b) {
				return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
			});
			return groupingArray;
        },
		CallPrintFunction() {
        window.print();
        },
		CompareBaseItems(ob1, ob2) {
			if (ob1.SortId === null && ob2.SortId !== null) return 1;
			else if (ob1.SortId !== null && ob2.SortId === null) return -1;

			if (ob1.SortId > ob2.SortId) {
				return 1;
			} else if (ob1.SortId < ob2.SortId) {
				return -1;
			}

			if (ob1.Title < ob2.Title) {
				return -1;
			} else if (ob1.Title > ob2.Title) {
				return 1;
			} else {
				return 0;
			}
        }

	}
};
</script>
<style lang="less" scoped>
    #app {
			.download

		{
			display: flex;
			width: 100%;
			flex-direction: column;
		}
		.download-link {
			display: inline;
			padding-right: 0.25em;
		}
    }
</style>
<style lang="less">
	@import "../assets/print.less";
	.title-language,
	.hero{
        visibility:hidden;
		height: 0px;
    }
</style>
